import {About, Appointments, Contact, Home} from './components';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import './styles/style.css';

function App() {
  return (
   <div className="Home">
     <Home />
     <BrowserRouter>
       <Switch>
         <Route path="/about">
           <About />
         </Route>
         <Route path="/appointments">
           <Appointments/>
         </Route>
         <Route path="/contact">
           <Contact/>
         </Route>
       </Switch>
     </BrowserRouter>
   </div>
  );
}

export default App;
