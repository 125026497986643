import '../styles/style.css';

function Home() {
  return (
    <div className="Menu">
      <a href="/about">Sobre</a>
      <a href="/appointments">Agendamento</a>
      <a href="/contact">Contato</a>
    </div>
  );
}

export default Home;
