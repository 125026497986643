import facebook from '../assets/facebook.png';
import instagram from '../assets/instagram.svg';
import whatsapp from '../assets/whatsapp.png';

import '../styles/style.css';

function Contact () {
  return (
    <div className="Contact">
      <a href="http://facebook.com" target="_blank"><img src={facebook} alt="facebook logo" className="Logo" /></a>
      <a href="http://instagram.com" target="_blank"><img src={instagram} alt="instagram logo" className="Logo" /></a>
      <a href="https://api.whatsapp.com/send?phone=55531231231233&text=Ol%C3%A1%2C%20gostaria%20de%20agendar%20um%20hor%C3%A1rio!" target="_blank"><img src={whatsapp} alt="whatsapp logo" className="Logo" /></a>
    </div>
  );
};

export default Contact;
